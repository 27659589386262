/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/bootstrap@4.5.0/dist/js/bootstrap.bundle.min.js
 * - /npm/@iconify/iconify@2.0.0/dist/iconify.min.js
 * - /npm/toastr@2.1.4/toastr.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/photoswipe@5.2.8/dist/umd/photoswipe-lightbox.umd.min.js
 * - /npm/photoswipe@5.2.8/dist/umd/photoswipe.umd.min.js
 * - /npm/xregexp@5.1.1/xregexp-all.min.js
 * - /npm/clappr@0.3.13/dist/clappr.min.js
 * - /npm/clappr-google-ima-html5-preroll-plugin@0.3.3/dist/clappr-google-ima-html5-preroll-plugin.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
